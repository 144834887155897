
const rulelist = [
	"https://www.monmouth.local/events/",
	"https://www.monmouth.edu/events/",
	"https://www.monmouth.local/now/",
	"https://www.monmouth.edu/now/"
];
function add(links) {
	// remove any new links from the list that match rulelist
	if( typeof links === "string" ) {
		links = [links];
	}
	let newLinks = links.filter(link => !rulelist.includes(link));

	// add newlinks to rulelist
	rulelist.push(...newLinks);

	if( newLinks.length === 0 ) {
		return;
	}

	// check if we can use speculationrules
	if (
		HTMLScriptElement.supports &&
		HTMLScriptElement.supports( "speculationrules" )
	) {
		let specScript = document.createElement( "script" );
		specScript.type = "speculationrules";
		let specRules = {
			prefetch: [
				{
					source: "list",
					urls: [...newLinks ],
				},
			],
		};
		specScript.textContent = JSON.stringify( specRules );
		document.body.append( specScript );
	// } else {
	// 	// handel legacy browsers - does not work as expected
	// 	links.forEach(link => {
	// 		let linkElem = document.createElement("link");
	// 		linkElem.rel = "prefetch";
	// 		linkElem.href = link;
	// 		document.head.append(linkElem);
	// 	});
	}
}


export default {
	add
};
