// helper to know if we should limit data. 
window.limitnetwork = ( () => {
	if (
		!navigator
		|| !navigator.connection
		|| !navigator.connection.effectiveType
	) {
		return null;
	}

	if ( navigator.connection.saveData === true ) {
		return true
	}

	switch ( navigator.connection.effectiveType ) {
		case '3g':
		case '4g':
			return false;
			break
		default:
			return true;
	}

} )();
// console.log( "Limit Data?", window.limitnetwork );
