/* search.js
 * Manages interactive events of the Header's search bar.
 * */

// elements comprising search form interaction
const searchOpenButton = document.getElementById('search-open');
const searchCloseButton = document.getElementById('search-close');
const searchForm = document.getElementById('searchForm');

// Determine whether an element belongs to another element by className
const findAncestor = (el, cls) => {
	while ((el = el.parentElement) && !el.classList.contains(cls));
	return el;
}

// Open the search and focus on the input
const searchOpen = event => {
	event.preventDefault();
	searchForm.classList.add('transition');
	setTimeout(() => searchForm.classList.add('open'), 10);
	setTimeout( 
		() => {
			searchForm.classList.remove('transition');
			document.getElementById( 'searchInput' ).focus();
		},
		250
	)
}

// Close the search when the button is clicked
const searchClose = event => {
	event.preventDefault();
	searchForm.classList.add('transition');
	searchForm.classList.remove('open');
	setTimeout(() => searchForm.classList.remove('transition'), 250);
}

// searchOpen button: add click and keyboard events
searchOpenButton.addEventListener('click', searchOpen);
searchOpenButton.addEventListener('keydown', event => {
	if (event.code == 'Space' || event.code == 'Enter') {
		searchOpen(event);
	}
});

// searchClose button: add click and keyboard events
searchCloseButton.addEventListener('click', searchClose);
searchCloseButton.addEventListener('keydown', event => {
	if (event.code == 'Space' || event.code == 'Enter') {
		searchClose(event);
	}
});

// search-form: add ability to exit search form with Escape key
document.addEventListener('keydown', event => {
	if (findAncestor(document.activeElement, 'search-form')) {
		if (event.code == 'Escape') {
			searchClose(event);
		}
	}   
});