import speculation from './speculation.js';


// get the next element a user could focus on after the open button
var nextFocusableElement = function ( currentElement ) {
	//derived from https://stackoverflow.com/questions/7208161/focus-next-element-in-tab-index
	//add all elements we want to include in our selection
	var focusableElements =
		'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
	var focusable = Array.prototype.filter.call(
		document.querySelectorAll( focusableElements ),
		function ( element ) {
			//check for visibility while always include the current activeElement
			return (
				element.offsetWidth > 0 ||
				element.offsetHeight > 0 ||
				element === document.activeElement
			);
		}
	);
	var index = focusable.indexOf( currentElement );
	if ( index > -1 ) {
		var nextElement = focusable[ index + 1 ] || focussable[ 0 ];
		return nextElement;
	}
}


var NavToggle = function () {
	function NavToggle( attr ) {
		this.isActive = false;

		// handle if the attr is a string, dom node, or json object
		if ( 'string' == typeof attr ) {
			attr = {
				'el': document.querySelector( attr )
			};
		} else if ( attr.nodeName ) {
			attr = {
				'el': attr
			};
		}

		// merge the settings
		this.settings = {
			'el': false,
			'button': false,
			'closeButton': false,
			'altOpenButton': false,
			'nextElement': false,
			'title': false,
			'hasSpeculation': false, 
			...attr
		}

		// If we don't have an element to work with, exit
		if ( this.settings.el === void 0 ) {
			return;
		}

		// if no button was provided then look for the first one in the el
		if ( !this.settings.button ) {
			this.settings.button = this.settings.el.querySelector( 'button' );
		}

		// if we have a close button, set its tab index to -1, we will programmatically set the focus to it.
		if ( this.settings.closeButton ) {
			this.settings.closeButton.setAttribute( 'tabindex', '-1' );
		}


		// if the event bus is active then listen for any all close events. 
		if ( window.eventBus ) {
			window.eventBus.on( 'navToggleAllClose', ( e ) => {
				if ( this.isActive ) {
					this.close();
				}
			} );
		}


		// handle keyboard events
		document.addEventListener( "keydown", ( e ) => {
			if ( this.isActive ) {
				// handle escape key
				if ( e.key === "Escape" ) {
					this.close( true );
				}

				// handle tab events
				if(e.target === this.settings.button && "Tab" === e.key){
					if ( false === e.shiftKey ) {
						// did not hold the shift key, go to the first element in the el
						e.preventDefault();
	
						children = this.settings.el.querySelectorAll( 'a, button' );
	
						for ( let child of children ) {
							// if its the open button, skip it
							if ( this.settings.button === child ) continue;
	
							// if the element is hidden, skip it
							let style = window.getComputedStyle( child );
							if ( 'none' === style.display ) continue;
	
							// focus on the element
							child.focus();
							break;
						}
					} else {
						this.close();
					}

				}
				
			}
		} );


		// Handel events on the main button.
		this.settings.button.addEventListener( 'click', ( e ) => {
			e.preventDefault();
			if ( this.settings.closeButton ) {
				// if a close button exists, then this one will only be for opening.
				this.open();
			} else {
				// toggle our status
				if ( this.isActive ) {
					this.close();
				} else {
					this.open();
				}
			}
		} );


		// if the alt open button is selected then open
		if ( this.settings.altOpenButton ) {
			this.settings.altOpenButton.addEventListener( 'click', ( e ) => {
				e.preventDefault();
				this.open();
			} );
		}


		// if we have a close button add a listener to close the nav items. 
		if ( this.settings.closeButton ) {
			this.settings.closeButton.addEventListener( 'click', ( e ) => {
				e.preventDefault();
				this.close();
			} );
		}


		// close if the user clicked outside of our el
		document.addEventListener( 'click', ( event ) => {
			if ( this.isActive ) {
				let isClickInside = this.settings.el.contains( event.target );
				if ( this.settings.button && this.settings.button.contains( event.target ) ) {
					return;
				}
				if ( this.settings.altOpenButton && this.settings.altOpenButton.contains( event.target ) ) {
					return;
				}
				if ( !isClickInside ) {
					this.close();
				}
			}
		} );


		// listen to see if the menu looses focus. 
		this.settings.el.addEventListener( "focusout", ( event ) => {
			if ( this.isActive && event.relatedTarget && !this.isChild( event.relatedTarget ) && this.settings.button  != event.relatedTarget ) {
				this.close();

				// if a next element is provided and its a function, execute it, if valid focus on that
				if ( this.settings.nextElement && typeof this.settings.nextElement === 'function' && this.settings.nextElement().focus() ) {
					this.settings.nextElement().focus();
					return;
				}

				// if next element is a dom element focus on that
				if ( this.settings.nextElement.nodeName ) {
					this.settings.nextElement.focus();
					return;
				}
				
				// focus on the next element after the initial button
				nextFocusableElement( this.settings.button ).focus();
			}
		} );

		
		// open the nav item
		this.open = function () {
			// emit an event if the event bus is active
			if ( window.eventBus ) {
				window.eventBus.emit( 'navToggleAllClose', this );
			}
			this.isActive = true;
			this.settings.el.classList.add( 'active' );
			this.settings.el.setAttribute('role', 'dialog');
			this.settings.el.setAttribute('aria-modal', true);
			if(this.settings.title){
				this.settings.el.setAttribute('aria-title', this.settings.title);
			}
			this.settings.button.classList.add( 'active' );
			this.settings.button.setAttribute('aria-expanded', 'true');
			if ( this.settings.closeButton ) {
				this.settings.closeButton.focus();
			}
			// preload any links in this menu
			let links = this.settings.el.querySelectorAll( 'a' );
			speculation.add( [... links].map( link => link.href ) );

		}


		// close the nav item, if focus = true then set the focus back to the open button
		this.close = function ( focus = false ) {
			this.isActive = false;
			this.settings.el.classList.remove( 'active' );
			this.settings.button.classList.remove( 'active' );
			this.settings.button.setAttribute('aria-expanded', 'false')
			if ( focus ) {
				this.settings.button.focus();
			}
		}


		// is the element passed in a child of our assigned el?
		this.isChild = function ( child ) {
			let elements = this.settings.el.querySelectorAll( 'a, input, textarea, button' );
			for ( let element of elements ) {
				if ( element === child ) {
					return true;
				}
			}
			return false;
		}

	}

	return NavToggle;
}();

export default NavToggle;
export { NavToggle, nextFocusableElement }
