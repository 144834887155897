// do we need a dialog polyfill? if so, load it
const scriptBasePath = document.getElementById('mu2022-js').src.split('?')[0].split('/').slice(0, -1).join('/') + '/';

if (window.HTMLDialogElement === undefined) {
    window.scriptLoader(scriptBasePath + 'dialog-polyfill.js', () => {

        // with the script asynchronously loaded, we need to apply the polyfill to any possible dialogs
        const alertdialog = document.getElementById('alert-modal');
        if (alertdialog) {

            // the script saves the polyfill to the window--we use it to register the alert
            window.dialogPolyfill.registerDialog(alertdialog)

            // display any alert that has no cookie associated with it
            new Array().forEach.call(alertdialog.children, child => {
                if (!getCookie(child.id)) {
                    alertdialog.showModal();
                }
            })
        }
    });
}

// export boolean for whether polyfill should be loaded
export default window.HTMLDialogElement === undefined ? true : false;