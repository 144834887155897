
// Convert A tags on the global nav to buttons for progressive enhancement for more semantic html when JS takes care of the interactions. 
const globalnav = document.getElementById( 'globalnav' );
if( globalnav ){

	const global_root = globalnav.querySelectorAll( '.parent-menu > li > a.has-child' );
	
	global_root.forEach( ( item ) => {
		// convert the a tags into buttons
		let b = document.createElement( 'button' );
		b.classList.value = item.classList.value;
		b.innerHTML = item.innerHTML;
		item.after( b );
		item.remove();
	
	} );	
}